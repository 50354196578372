const emailDiv = "#content-email-modal";
const textDiv = "#content-text-modal";
const responseDiv = "#reset-password-modal-content-response";
const responsefirstConnectionDiv = "#first-connection-modal-content-response";
const emailfirstConnectionDiv = "#content-email-first-connection-modal";
const textfirstConnectionDiv = "#content-first-connection-text-modal";
$(document).ready(function () {
    bindAuthenticationActions();
    $('[data-toggle="tooltip"]').tooltip({
        html: true
    });
    window.formSubmitted = false;

    window.currentModalName = '';

    function bindAuthenticationActions() {

        let events = [
            {type: 'shown.bs.modal', elt: null, callback: bsModalShow},
            {type: 'shown.bs.modal', elt: '#first-connection-modal', callback: firstConnexionShow},
            {type: 'shown.bs.modal', elt: '#reset-password-modal', callback: resetPasswordShow},

            {type: 'click', elt: '#reset-reset-password-modal-content', callback: resetResetPasswordModalContent},
            {type: 'click', elt: '#reset-password-button', callback: authenticateResetPasswordDefault},
            {type: 'click', elt: '#reset-password-button-error', callback: authenticateResetPasswordError},
            {type: 'click', elt: '#send-password-button', callback: sendFirstPassword},

            {type: 'click', elt: '#reset-first-connection-modal-content', callback: resetfirstConnectionModalContent},
            {type: 'click', elt: '#first-connection-button', callback: firstConnection},
            {type: 'click', elt: '#first-connection-button-error', callback: firstConnectionError},
            {type: 'click', elt: '#send-password-button-first-connection', callback: sendfirstConnectionPassword},

            {type: 'click', elt: '#btn-close-first-connexion', callback: closeModalFirstConnexion},
            {type: 'click', elt: '#btn-close-reset-password', callback: closeModalResetPassword},


        ]

        // UNBIND

        for (let i = 0; i < events.length; i++) {
            let o = events[i];
            if (null == o.elt) {
                $(document).off(o.type, o.callback);
            } else {
                $(document).off(o.type, o.elt, o.callback);
            }
        }

        // BIND

        for (let i = 0; i < events.length; i++) {
            let o = events[i];
            if (null == o.elt) {
                $(document).on(o.type, o.callback);
            } else {
                $(document).on(o.type, o.elt, o.callback);
            }
        }

        /**
         * This function enables click on ENTER keyboard touch to submit form by clicking button
         */
        $("form").keypress(function (e) {

            if ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13)) {
                var button = $(this).find('button');
                var inputButton = $(this).find('input.button.green');

                if (button.length > 0) {
                    button.click();
                }

                if (inputButton.length > 0) {
                    inputButton.click();
                }

                return false;
            }
        });
    }

    function bsModalShow() {
        $('.tooltips').tooltip();
    }

    function firstConnexionShow() {
        $('#first-connection-modal-content-default').removeClass('d-none');
        $('#first-connection-modal-content-response').addClass('d-none');
        setCurrentModal('first-connexion-email-show');
    }

    function resetPasswordShow() {
        $('#reset-password-modal-content-default').removeClass('d-none');
        $('#reset-password-modal-content-response').addClass('d-none');
        setCurrentModal('forgot-password-email-show');
    }

    function resetResetPasswordModalContent() {
        // Disabling link (on mobile and desktop) to prevent multiplie OTP code sending
        disableResendOTPLink();
        var form = $('#reset-password-form');
        authenticateResetPassword(form);
    }

    function resetfirstConnectionModalContent() {
        // Disabling link (on mobile and desktop) to prevent multiplie OTP code sending
        disableResendOTPLink();
        var form = $('#first-connection-form');
        authenticatefirstConnection(form);
    }

    function authenticateResetPasswordDefault() {
        var form = $('#reset-password-form');
        authenticateResetPassword(form);
    }

    function firstConnection() {
        var form = $('#first-connection-form');
        authenticatefirstConnection(form);
    }

    function authenticateResetPasswordError() {
        var form = $('#reset-password-form-error');
        authenticateResetPassword(form);
    }

    function firstConnectionError() {
        var form = $('#first-connection-form-error');
        authenticatefirstConnection(form);
    }

    function authenticatefirstConnection(form) {
        if (window.formSubmitted) {
            return;
        }
        window.formSubmitted = true;

        $.ajax({
            type: form.attr('method'), // define the type of HTTP verb we want to use (POST for our form)
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function (data) {
                setCurrentModal(data.view_event_name);
                $('#first-connection-modal-content-default').addClass('d-none');
                $('#first-connection-modal-content-response').removeClass('d-none');
                $('#first-connection-modal-content-response').html(data.html);
                $('[data-toggle="tooltip"]').tooltip({
                    html: true
                });
                disableResendOTPLink(5000);
            }
        }).done(function (data) {
            bindAuthenticationActions();
            window.formSubmitted = false;
        });

    }

    function authenticateResetPassword(form) {
        if (window.formSubmitted) {
            return;
        }
        window.formSubmitted = true;

        $.ajax({
            type: form.attr('method'), // define the type of HTTP verb we want to use (POST for our form)
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function (data) {
                setCurrentModal(data.view_event_name);
                $('#reset-password-modal-content-default').addClass('d-none');
                $('#reset-password-modal-content-response').removeClass('d-none');
                $('#reset-password-modal-content-response').html(data.html);
                $('[data-toggle="tooltip"]').tooltip({
                    html: true
                });
                disableResendOTPLink(5000);
            }
        }).done(function (data) {
            bindAuthenticationActions();
            window.formSubmitted = false;
        });
    }

    function sendfirstConnectionPassword() {
        if (window.formSubmitted) {
            return;
        }

        window.formSubmitted = true;
        var form = $('#send-password-form-first-connection');
        $.ajax({
            type: form.attr('method'), // define the type of HTTP verb we want to use (POST for our form)
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json', // what type of data do we expect back from the server
        }).done(function (data) {
            setCurrentModal(data.view_event_name);

            $('#first-connection-modal-content-response').html(data.html);
            $('[data-toggle="tooltip"]').tooltip();
            bindAuthenticationActions();
            window.formSubmitted = false;
        });
    }

    function sendFirstPassword() {
        if (window.formSubmitted) {
            return;
        }

        window.formSubmitted = true;
        var form = $('#send-password-form');
        $.ajax({
            type: form.attr('method'), // define the type of HTTP verb we want to use (POST for our form)
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json', // what type of data do we expect back from the server
        }).done(function (data) {
            setCurrentModal(data.view_event_name);

            $('#reset-password-modal-content-response').html(data.html);
            $('[data-toggle="tooltip"]').tooltip();
            bindAuthenticationActions();
            window.formSubmitted = false;
        });
    }

    /**
     * Disable the link for the given duration or permanently if not specified
     * @param {number} duration How long the link will be disabled, in millisecondes.
     */
    function disableResendOTPLink(duration) {
        $(".decorated-link-resend-otp").each(function () {
            $(this).addClass("disabled");

            if (duration) {
                $(this)
                    .delay(duration)
                    .queue(function (next) {
                        $(this).removeClass("disabled");
                        next();
                    });
            }
        })
    }

    function closeModalFirstConnexion() {
        window.dispatchEvent(new CustomEvent('tagcoevent', { detail: {tagcoid: window.currentModalName.split('-show').join('-close-clic')} }));
        $('#first-connection-modal').modal('hide');
        window.currentModalName = '';
    }

    function closeModalResetPassword() {
        window.dispatchEvent(new CustomEvent('tagcoevent', { detail: {tagcoid: window.currentModalName.split('-show').join('-close-clic')} }));
        $('#reset-password-modal').modal('hide');
        window.currentModalName = '';
    }



    function setCurrentModal(modalName) {
        if (window.currentModalName != modalName) {
            window.dispatchEvent(new CustomEvent('tagcoevent', { detail: {tagcoid: modalName} }));
        }
        window.currentModalName = modalName;
    }

    var $ripples = $('.ripples');

    $ripples.on('click.Ripples', function(e) {

        var $this = $(this);
        var $offset = $this.parent().offset();
        var $circle = $this.find('.ripplesCircle');

        var x = e.pageX - $offset.left;
        var y = e.pageY - $offset.top;

        $circle.css({
            top: y + 'px',
            left: x + 'px'
        });


        $this.addClass('is-active');

    });

    $ripples.on('animationend webkitAnimationEnd mozAnimationEnd oanimationend MSAnimationEnd', function(e) {
        $(this).removeClass('is-active');
    });

    $('.floa input').on('input change keyup paste focus blur', function(e){
        var val = $(this).val();
        if(val !== ""){
            $(this).addClass('used')
        }else{
            $(this).removeClass('used')
        }
    });

    $('html').bind('input', function() {
        $.each($('input'),function() {
            $(this).addClass('used');
        });
    });

    setTimeout(function() {
        $('.floa input').each(function() {
            var elem = $(this);
            if (elem.val()) elem.change();
        })
    }, 250);
    
});
